import { isDevMode, NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { HttpClientModule } from '@angular/common/http';

import { HOOK_PROVIDERS } from './hooks';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { AppComponent } from './app.component';
import { AuthRootModule } from '~/auth/auth-root.module';
import { DictionaryRootModule } from '~/dictionary/dictionary-root.module';
import { ContactRootModule } from '~/contact/contact-root.module';
import { ActivityRootModule } from '~/activity/@root';
import { SubscriptionRootModule } from '~/subscription/subscription-root.module';
import { UserRootModule } from '~/user/user-root.module';
import { initializers } from '~/@core/initializers';
import { ConfirmModule } from '~/@core/modules/confirm';
import { ErrorDialogModule } from '~/@core/modules/error-dialog';

const featureRootModules = [
  AuthRootModule,
  DictionaryRootModule,
  ContactRootModule,
  ActivityRootModule,
  SubscriptionRootModule,
  UserRootModule,
];

const commonModules = [ConfirmModule, ErrorDialogModule];

@NgModule({
  imports: [
    HttpClientModule,
    StoreModule.forRoot(),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    TranslateModule.forRoot(),

    AppLayoutModule,
    AppRoutingModule,

    ...commonModules,
    ...featureRootModules,
  ],
  declarations: [AppComponent],
  providers: [
    ...initializers,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    ...HOOK_PROVIDERS,
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
