import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, of, switchMap, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Action, Store } from '@ngrx/store';

import { AuthHttpService } from '~/auth/services/http';
import { login, logout } from '../authentication/authentication.actions';
import { selectIsLoggedIn } from '../authentication/authentication.selectors';
import {
  clearAbilities,
  fetchAbilities,
  fetchAbilitiesFail,
  initAbilities,
  setAbilities,
} from './access.actions';

@Injectable()
export class AccessEffects implements OnInitEffects {
  public readonly getAbilitiesOnLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(login),
      map(() => fetchAbilities()),
    );
  });

  public readonly clearAbilitiesOnLogout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logout),
      map(() => clearAbilities()),
    );
  });

  public readonly getAbilitiesOnInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initAbilities),
      concatLatestFrom(() => this.store.select(selectIsLoggedIn)),
      filter(([, isLoggedIn]) => isLoggedIn),
      map(() => fetchAbilities()),
    );
  });

  public readonly fetchAbilities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchAbilities),
      switchMap(() =>
        this.authHttp.getAbilities().pipe(
          map((abilitiesCollection) =>
            setAbilities({ items: abilitiesCollection.items }),
          ),
          catchError((err, caught) => {
            return of(fetchAbilitiesFail());
          }),
        ),
      ),
    );
  });

  public fetchAbilitiesFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fetchAbilitiesFail),
        tap(() => this.showAccessFailMessage()),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly authHttp: AuthHttpService,
    private readonly messageService: MessageService,
  ) {}

  private showAccessFailMessage() {
    this.messageService.add({
      severity: 'danger',
      summary: 'auth.message.accessFetchFailHeader',
      detail: 'auth.message.accessFetchFailDetail',
    });
  }

  public ngrxOnInitEffects(): Action {
    return initAbilities();
  }
}
