import { Pipe, PipeTransform } from '@angular/core';
import { EntityControl } from '~/auth/models';
import { entityHasAbility } from './entity-not-permitted.pipe';

@Pipe({
  name: 'entityPermitted',
  pure: true,
  standalone: true,
})
export class EntityPermittedPipe implements PipeTransform {
  transform(value: EntityControl, permission: string): any {
    return entityHasAbility(value, permission);
  }
}
