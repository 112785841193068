import { createSelector } from '@ngrx/store';

import { selectAuthFeature } from '../feature.selector';

export const selectAuthenticationFeature = createSelector(
  selectAuthFeature,
  (state) => state.authentication,
);

export const selectAuthToken = createSelector(
  selectAuthenticationFeature,
  (state) => state.authToken,
);

export const selectIsLoggedIn = createSelector(
  selectAuthenticationFeature,
  (state) => !!state.authToken,
);

export const selectTokenPayload = createSelector(selectAuthToken, (token) => {
  if (!token) {
    return null;
  }

  const [, payload] = token.split('.');
  return JSON.parse(atob(payload));
});

export const selectUserName = createSelector(selectTokenPayload, (payload) => {
  if (!payload) {
    return null;
  }

  return payload.username;
});

export const selectUserId = createSelector(selectTokenPayload, (payload) => {
  if (!payload) {
    return null;
  }

  return payload.sub;
});
