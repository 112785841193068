import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { selectHasAbility } from '~/auth/store/access/access.selector';

@Directive({
  standalone: true,
  selector: '[appIfHasAbility]',
})
export class UnlessDirective {
  private permissionName$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  @Input() set appIfHasAbility(permissionName: string) {
    this.permissionName$.next(permissionName);
  }

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly store: Store,
  ) {
    this.permissionName$
      .pipe(
        switchMap((permissionName) => {
          return this.store.select(selectHasAbility(permissionName));
        }),
      )
      .subscribe((hasAbility) => {
        if (hasAbility) this.viewContainer.createEmbeddedView(this.templateRef);
        else this.viewContainer.clear();
      });
  }
}
