import { createSelector } from '@ngrx/store';
import { selectAuthFeature } from '~/auth/store/feature.selector';
import { abilityEntityAdapter } from '~/auth/store/access/access.reducer';

const entitySelectors = abilityEntityAdapter.getSelectors();

export const selectAccessSlice = createSelector(
  selectAuthFeature,
  (state) => state.access,
);

export const selectHasAbility = (permisssion: string) =>
  createSelector(selectAccessSlice, (state) => {
    const abilities = entitySelectors.selectEntities(state);
    return !!abilities[permisssion]?.hasAccess;
  });
