import { Injectable } from '@angular/core';
import { IAuthTokenResponse } from '../models/auth-token-response.interface';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  catchError,
  first,
  mergeMap,
  Observable,
  shareReplay,
  throwError,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';

import { selectAuthToken } from '../store/authentication/authentication.selectors';
import { AuthHttpService } from '../services/http';
import {
  logout,
  refreshAuthToken,
} from '../store/authentication/authentication.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenRequest: Observable<IAuthTokenResponse> | null = null;

  constructor(
    private store: Store,
    private authHttp: AuthHttpService,
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.store.select(selectAuthToken).pipe(
      first(),
      mergeMap((authToken) => {
        const authReq = !!authToken ? this.addAuthHeader(req, authToken) : req;

        return next.handle(authReq).pipe(
          catchError((originalError: HttpErrorResponse) => {
            if (!authToken || !this.isExpiredError(originalError)) {
              return throwError(() => originalError);
            }

            if (!this.refreshTokenRequest) {
              this.refreshTokenRequest = this.authHttp.refresh().pipe(
                switchMap((res) => {
                  this.refreshTokenRequest = null;
                  this.store.dispatch(
                    refreshAuthToken({ token: res.accessToken }),
                  );
                  return new Observable<IAuthTokenResponse>((observer) => {
                    observer.next(res);
                    observer.complete();
                  });
                }),
                catchError((error) => {
                  this.refreshTokenRequest = null;
                  this.store.dispatch(logout());
                  return throwError(() => error);
                }),
                shareReplay(1),
              );
            }

            return this.refreshTokenRequest.pipe(
              switchMap((res) => {
                return next.handle(this.addAuthHeader(req, res.accessToken));
              }),
              catchError((refreshError) => {
                return throwError(() => refreshError);
              }),
            );
          }),
        );
      }),
    );
  }

  private isExpiredError(error: HttpErrorResponse) {
    return error.status === 401 && error.error?.message === 'Token expired';
  }

  private addAuthHeader(request: HttpRequest<any>, authToken: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  }
}
