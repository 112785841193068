import { createAction, props } from '@ngrx/store';
import { Ability } from '~/auth/models';

export const initAbilities = createAction('[Auth] Init abilities');
export const fetchAbilities = createAction('[Auth] Fetch abilities');
export const fetchAbilitiesFail = createAction('[Auth] Fetch abilities fail');
export const setAbilities = createAction(
  '[Auth] Set abilities',
  props<{ items: Ability[] }>(),
);
export const clearAbilities = createAction('[Auth] Clear abilities');
