import { ActionReducer, INIT, MetaReducer, UPDATE } from '@ngrx/store';

import {
  login,
  logout,
  refreshAuthToken,
} from './authentication/authentication.actions';
import { IAuthState } from './reducer';

const LOCAL_STORAGE_AUTH_KEY = 'store.auth';

function localStorageAuthSyncMetaReducer(
  reducer: ActionReducer<IAuthState>,
): ActionReducer<IAuthState> {
  return (state, action) => {
    const nextState = reducer(state, action);

    if (action.type === INIT || action.type === UPDATE) {
      const storageAuthValue = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
      if (storageAuthValue) {
        try {
          const rehydratedState = { ...nextState };
          rehydratedState.authentication = {
            authToken: storageAuthValue,
          };

          return rehydratedState;
        } catch {
          localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
        }
      }
    }

    if (
      action.type === login.type ||
      action.type === refreshAuthToken.type ||
      action.type === logout.type
    )
      if (!nextState.authentication.authToken) {
        localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
      } else {
        localStorage.setItem(
          LOCAL_STORAGE_AUTH_KEY,
          nextState.authentication.authToken,
        );
      }

    return nextState;
  };
}

export const metaReducers: MetaReducer<IAuthState>[] = [
  localStorageAuthSyncMetaReducer,
];
