import { Pipe, PipeTransform } from '@angular/core';
import { EntityControl } from '~/auth/models';

export const entityHasAbility = (value: EntityControl, permission: string) => {
  return value.permissions.some(
    (a) => a.permissionName === permission && a.hasAccess,
  );
};

@Pipe({
  name: 'entityNotPermitted',
  pure: true,
  standalone: true,
})
export class EntityNotPermittedPipe implements PipeTransform {
  transform(value: EntityControl, permission: string): any {
    return !entityHasAbility(value, permission);
  }
}
