<p-toast position="bottom-right">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column align-items-start gap-2" style="flex: 1">
      <div class="font-medium">
        {{ message.summary | translate }}
      </div>
      <div class="text-800" translate [translateParams]="message.data">
        {{ message.detail }}
      </div>
    </div>
  </ng-template>
</p-toast>
<div class="layout-container" [ngClass]="containerClass">
  <app-sidebar></app-sidebar>
  <div class="layout-content-wrapper">
    <div class="layout-content" #content>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>
