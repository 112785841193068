import { Injectable } from '@angular/core';

/**
 * Proxy service to export data. Asynchronously loads the real service and delegates the call to it.
 */
@Injectable()
export class ExportService {
  public async export(rows: Array<{ [key: string]: any }>) {
    await import('./real-export.service')
      .then((module) => module.RealExportService)
      .then((service) => new service())
      .then((instance) => {
        instance.export(rows);
      });
  }
}
