import { createReducer, on } from '@ngrx/store';
import { login, logout, refreshAuthToken } from './authentication.actions';

export interface IAuthenticationState {
  authToken: string | null;
}

export const createAuthInitialState = (): IAuthenticationState => ({
  authToken: null,
});

export const authenticationReducer = createReducer<IAuthenticationState>(
  createAuthInitialState(),
  on(
    login,
    (state, { token }): IAuthenticationState => ({
      ...state,
      authToken: token,
    }),
  ),
  on(
    refreshAuthToken,
    (state, { token }): IAuthenticationState => ({
      ...state,
      authToken: token,
    }),
  ),
  on(
    logout,
    (state): IAuthenticationState => ({
      ...state,
      authToken: null,
    }),
  ),
);
