import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ADMIN_MENU_ITEM_TOKEN } from '~/layout/menu-item.token';

const menuItemsFactory = (trans: TranslateService) => [
  {
    label: trans.instant('auth.menu.roles'),
    icon: 'pi pi-key',
    routerLink: ['/roles'],
    abilityCheck: 'authorization:roles:manage',
  },
];

@NgModule({
  providers: [
    {
      provide: ADMIN_MENU_ITEM_TOKEN,
      deps: [TranslateService],
      multi: true,
      useFactory: menuItemsFactory,
    },
  ],
})
export class RoleManagementRootModule {
  public constructor(
    @Optional() @SkipSelf() parentModule?: RoleManagementRootModule,
  ) {
    if (parentModule) {
      throw new Error(
        'RoleManagementRootModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
