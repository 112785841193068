import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Ability } from '../../models';
import { clearAbilities, setAbilities } from './access.actions';

export const abilityEntityAdapter = createEntityAdapter<Ability>({
  selectId: (ability) => ability.permissionName,
});

export interface IAccessState extends EntityState<Ability> {}

const createInitState = (): IAccessState =>
  abilityEntityAdapter.getInitialState();

export const accessReducer = createReducer<IAccessState>(
  createInitState(),
  on(
    setAbilities,
    (state, { items }): IAccessState =>
      abilityEntityAdapter.setMany(items, state),
  ),
  on(
    clearAbilities,
    (state): IAccessState => abilityEntityAdapter.removeAll(state),
  ),
);
