import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { login, logout, refreshAuthToken } from './authentication.actions';
import { AuthHttpService } from '~/auth/services/http';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthenticationEffects {
  private refreshTokenTimeout: NodeJS.Timeout | null = null;

  public readonly refreshTokenOnLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(login, refreshAuthToken),
        tap(({ token }) => {
          const tokenData = JSON.parse(atob(token.split('.')[1]));
          const expiresIn = tokenData.exp - tokenData.iat;

          if (expiresIn < 60) {
            return;
          }
          const refreshIn = expiresIn - 5;

          if (this.refreshTokenTimeout) {
            clearTimeout(this.refreshTokenTimeout);
          }

          this.refreshTokenTimeout = setTimeout(() => {
            this.authHttp.refresh().subscribe((res) => {
              this.store.dispatch(refreshAuthToken({ token: res.accessToken }));
            });
          }, refreshIn * 1000);
        }),
      );
    },
    { dispatch: false },
  );

  public readonly navigateToLoginOnLogout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logout),
        tap(() => {
          void this.router.navigateByUrl('/login');
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly authHttp: AuthHttpService,
    private readonly store: Store,
  ) {}
}
