import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { MENU_ITEM_TOKEN } from '~/layout/menu-item.token';

import { contactFeatureKey, contactReducer } from './store/reducer';
import { contactEffects } from './store/effects';

const menuItemsFactory = (trans: TranslateService) => [
  {
    label: trans.instant('contact.menu.contacts'),
    icon: 'pi pi-book',
    items: [
      {
        label: trans.instant('contact.menu.companies'),
        icon: 'pi pi-building',
        routerLink: ['/contacts/companies'],
        abilityCheck: ['contact:contacts:read'],
      },
      {
        label: trans.instant('contact.menu.persons'),
        icon: 'pi pi-users',
        routerLink: ['/contacts/persons'],
        abilityCheck: ['contact:contacts:read'],
      },
      {
        label: trans.instant('contact.menu.trash'),
        icon: 'pi pi-trash',
        routerLink: ['/contacts/deleted'],
        abilityCheck: ['contact:contacts:permanentDelete'],
      },
      {
        label: trans.instant('contact.menu.configuration'),
        icon: 'pi pi-cog',
        routerLink: ['/contacts/configuration'],
      },
    ],
  },
];

@NgModule({
  imports: [
    StoreModule.forFeature(contactFeatureKey, contactReducer),
    EffectsModule.forFeature(contactEffects),
  ],
  providers: [
    {
      provide: MENU_ITEM_TOKEN,
      deps: [TranslateService],
      multi: true,
      useFactory: menuItemsFactory,
    },
  ],
})
export class ContactRootModule {
  public constructor(@Optional() @SkipSelf() parentModule?: ContactRootModule) {
    if (parentModule) {
      throw new Error(
        'ContactRootModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
