import {
  RouterModule,
  Routes,
  UrlMatchResult,
  UrlSegment,
} from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/components/layout/app.layout.component';
import { AuthenticatedGuard, hasAbilityGuard } from './auth/guards';

const routes: Routes = [
  {
    matcher: (segments: UrlSegment[]): UrlMatchResult | null => {
      const validPaths = [
        'login',
        'forgot-password',
        'reset-password',
        'access',
      ];
      if (segments.length && validPaths.includes(segments[0].path)) {
        return {
          consumed: [],
        };
      }

      return null;
    },
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticatedGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./homepage').then((m) => m.HomepageComponent),
      },
      {
        path: 'contacts',
        loadChildren: () =>
          import('./contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'roles',
        canActivate: [hasAbilityGuard('authorization:roles:manage')],
        loadChildren: () =>
          import('./auth/modules/role-management').then(
            (m) => m.RoleManagementModule,
          ),
      },
      {
        path: 'dictionaries',
        loadChildren: () =>
          import('./dictionary/dictionary.module').then(
            (m) => m.DictionaryModule,
          ),
      },
      {
        path: 'activities',
        loadChildren: () =>
          import('./activity/activity.module').then((m) => m.ActivityModule),
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import('./subscription/subscription.module').then(
            (m) => m.SubscriptionModule,
          ),
      },
    ],
  },
  {
    path: 'notfound',
    loadChildren: () =>
      import('./@core/pages/notfound/notfound.module').then(
        (m) => m.NotfoundModule,
      ),
  },
  { path: '**', redirectTo: '/notfound' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
