import { ActionReducerMap } from '@ngrx/store';

import {
  authenticationReducer,
  IAuthenticationState,
} from './authentication/authentication.reducer';
import { accessReducer, IAccessState } from './access/access.reducer';

export interface IAuthState {
  authentication: IAuthenticationState;
  access: IAccessState;
}

export const authReducer: ActionReducerMap<IAuthState> = {
  authentication: authenticationReducer,
  access: accessReducer,
};

export const authFeatureKey = 'auth';
