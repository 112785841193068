import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { TabMenuModule } from 'primeng/tabmenu';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';

const primeNgModules = [
  TagModule,
  RippleModule,
  ButtonModule,
  InputTextModule,
  TableModule,
  DialogModule,
  DropdownModule,
  DynamicDialogModule,
  TooltipModule,
  TabMenuModule,
  CalendarModule,
  TabViewModule,
  BadgeModule,
  ProgressSpinnerModule,
  MultiSelectModule,
  CheckboxModule,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild({
      extend: true,
    }),

    ...primeNgModules,
  ],

  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,

    ...primeNgModules,
  ],
})
export class SharedExtModule {}
