import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, tap } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectHasAbility } from '~/auth/store/access/access.selector';

export const hasAbilityGuard = (roles: string): CanActivateFn => {
  return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> => {
    const store = inject(Store);
    const router = inject(Router);

    return store.select(selectHasAbility(roles)).pipe(
      tap((hasAbility) => {
        if (!hasAbility) {
          router.navigate(['/access']);
        }
      }),
    );
  };
};
