import { createSelector } from '@ngrx/store';
import { selectContactFeature } from '../feature.selector';
import { dictionaryItemsAdapter } from './contact-dictionary.reducer';
import { ContactRestriction } from '~/contact/models';

const { selectAll } = dictionaryItemsAdapter.getSelectors();

const selectItemsSlice = createSelector(
  selectContactFeature,
  (state) => state.contactDictionaries,
);

export const selectContactDictionaryLoading = createSelector(
  selectItemsSlice,
  (state) => state?.loading || false,
);

export const selectContactDictionaryLoaded = createSelector(
  selectItemsSlice,
  (state) => state?.loading || false,
);

export const selectContactDictionaryList = createSelector(
  selectItemsSlice,
  (state) => (state?.entities ? selectAll(state.entities) : []),
);

export const selectCompanyDictionaryList = createSelector(
  selectContactDictionaryList,
  (dictionaries) =>
    dictionaries.filter((d) =>
      [ContactRestriction.Company, ContactRestriction.Any].includes(
        d.contactType,
      ),
    ),
);

export const selectPersonDictionaryList = createSelector(
  selectContactDictionaryList,
  (dictionaries) =>
    dictionaries.filter((d) =>
      [ContactRestriction.Person, ContactRestriction.Any].includes(
        d.contactType,
      ),
    ),
);

export const selectContactDictionaryId = (id: string) =>
  createSelector(
    selectItemsSlice,
    (state) => state.entities.entities[id] || null,
  );
